import React, { Component } from "react"
import ReactPannellum from "react-pannellum"
import PropTypes from "prop-types"
import { objetEstDefini } from "../../js/client-es/utils"

export default class Pannellum extends Component {
  constructor(props) {
    super(props)

    this.urlImageSource = props.urlImageSource
    if (objetEstDefini(props.altitudeParDefaut))
      this.altitudeParDefaut = props.altitudeParDefaut
    else this.altitudeParDefaut = 20

    if (objetEstDefini(props.offsetNord)) {
      this.offsetNord = props.offsetNord
    } else this.offsetNord = 0

    if (objetEstDefini(props.angleDeVue)) {
      this.angleDeVue = props.angleDeVue
    } else this.angleDeVue = 120

    if (objetEstDefini(props.angleDeVueVertical)) {
      this.angleDeVueVertical = props.angleDeVueVertical
    } else this.angleDeVueVertical = 180
  }

  render() {
    const config = {
      autoLoad: true,
      autoRotate: 5,
      hfov: this.angleDeVue,
      pitch: this.altitudeParDefaut,
      northOffset: this.offsetNord,
      autoRotateInactivityDelay: 5000,
      vaov: this.angleDeVueVertical,
      uiText: {
        bylineLabel: "",
      },
    }

    const style = {
      width: "100%",
      height: "100%",
    }

    return (
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={this.urlImageSource}
        config={config}
        style={style}
      />
    )
  }
}

Pannellum.propTypes = {
  urlImageSource: PropTypes.string,
  altitudeParDefaut: PropTypes.number,
  offsetNord: PropTypes.number,
  angleDeVue: PropTypes.number,
  angleDeVueVertical: PropTypes.number,
}
