import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageHessII360: allPagesCompilationYaml(
      filter: { code: { eq: "hessII360EN" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["telescopeHESSII"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
  }
`

export default function HessII360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageHessII360")
  const imageAVendre = obtenirImages(resultatsRequete, "imageAVendre")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/hessII.jpg"
      altitudeParDefaut={30}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          The Milky Way stretches above the largest optical telescope in the
          world: the HESS II. With its mirror 28 meters in diameter, this
          telescope is a veritable cathedral of metal, terribly impressive when
          you are at his feet. Located in the heart of Namibian desert, the HESS
          II telescope studies the gamma radiations emitted by cosmic
          cataclysms: supernovas, holes blacks, pulsars… in order to better
          understand them.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          32 images panoramic, Nodal Ninja 3 panoramic head, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F2, ISO 3200.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(imageAVendre, "telescopeHESSII")}
            langue={page.langue}
          />
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
