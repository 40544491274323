import React from "react"
import PropTypes from "prop-types"

import Lien from "../navigation/Lien"
import { locales } from "../../../locales"

const TAILLE_TEXTE_MAX_AUTORISEE_PAR_TWITTER = 279

export default function BoutonPartageTwitter({ classeCSS, page }) {
  return (
    <div className={classeCSS}>
      <Lien
        urlExterne={`https://twitter.com/intent/tweet?text=${page.titrePourSEOEtLien.substring(
          0,
          TAILLE_TEXTE_MAX_AUTORISEE_PAR_TWITTER
        )}&url=${page.urlPartage}`}
        label={locales[page.langue].site.partagerSurFacebook}
      >
        <svg viewBox="0 0 32 32">
          <g id="Flat_copy">
            <g>
              <g>
                <path
                  d="M16,31.625c-8.616,0-15.625-7.01-15.625-15.625S7.384,0.375,16,0.375c8.615,0,15.625,7.01,15.625,15.625     S24.615,31.625,16,31.625z"
                  fill="#FFFFFF"
                />
                <path
                  d="M16,0.75c8.409,0,15.25,6.841,15.25,15.25S24.409,31.25,16,31.25S0.75,24.409,0.75,16S7.591,0.75,16,0.75      M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0L16,0z"
                  fill="#E5E5E5"
                />
              </g>
            </g>
            <path
              d="M18.226,8.886c-1.59,0.579-2.595,2.071-2.481,3.704l0.038,0.63l-0.636-0.077   c-2.315-0.296-4.338-1.299-6.056-2.984l-0.84-0.836L8.036,9.94c-0.458,1.376-0.165,2.83,0.789,3.808   c0.509,0.54,0.394,0.617-0.483,0.296c-0.305-0.103-0.573-0.18-0.598-0.141c-0.089,0.09,0.216,1.26,0.458,1.724   c0.331,0.644,1.005,1.273,1.743,1.647l0.624,0.296L9.83,17.581c-0.712,0-0.738,0.013-0.661,0.284   c0.254,0.836,1.259,1.724,2.379,2.11l0.789,0.27l-0.687,0.412c-1.018,0.593-2.214,0.927-3.41,0.951   c-0.573,0.013-1.044,0.064-1.044,0.103c0,0.128,1.553,0.848,2.455,1.132c2.71,0.836,5.929,0.475,8.346-0.952   c1.718-1.016,3.435-3.036,4.237-4.992c0.433-1.041,0.865-2.945,0.865-3.858c0-0.592,0.038-0.669,0.75-1.376   c0.42-0.412,0.814-0.862,0.891-0.99c0.128-0.245,0.114-0.245-0.534-0.026c-1.081,0.386-1.234,0.335-0.699-0.244   c0.394-0.412,0.865-1.158,0.865-1.376c0-0.038-0.191,0.026-0.407,0.141c-0.229,0.129-0.738,0.322-1.12,0.437l-0.687,0.219   L21.535,9.4c-0.344-0.231-0.826-0.489-1.081-0.566C19.804,8.654,18.812,8.68,18.226,8.886z"
              fill="#5F5F5F"
            />
          </g>
        </svg>
      </Lien>
    </div>
  )
}

BoutonPartageTwitter.propTypes = {
  classeCSS: PropTypes.string,
  page: PropTypes.object,
}
