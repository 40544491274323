import React from "react"
import PropTypes from "prop-types"

import BoutonPartageFacebook from "../boutons/BoutonPartageFacebook"
import BoutonPartageTwitter from "../boutons/BoutonPartageTwitter"

import { locales } from "../../../locales"

export default function PartageReseauxSociaux({ page }) {
  if (page.partageReseauxSociaux) {
    return (
      <div id="partage-reseaux-sociaux-conteneur">
        <label className="partage-reseaux-sociaux-texte-partage">
          {locales[page.langue].site.partager + " :"}
        </label>
        <BoutonPartageFacebook
          classeCSS="partage-reseaux-sociaux-conteneur-icone"
          page={page}
        />
        <BoutonPartageTwitter
          classeCSS="partage-reseaux-sociaux-conteneur-icone"
          page={page}
        />
      </div>
    )
  } else {
    return null
  }
}

PartageReseauxSociaux.propTypes = {
  page: PropTypes.object,
}
