import React from "react"
import PropTypes from "prop-types"
import Lien from "../navigation/Lien"

export default function BoutonChariot({ codePageCible, classeCSS }) {
  return (
    <button type="button" className={classeCSS}>
      <Lien codePageCible={codePageCible} ancre="section-acheter-tirage">
        <svg
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="11" />
          <g>
            <path
              d="m9.5551 15.549c-0.67236 0-1.2164 0.53237-1.2164 1.183 0 0.65067 0.544 1.183 1.2164 1.183 0.67236 0 1.2225-0.53237 1.2225-1.183 0-0.65067-0.55011-1.183-1.2225-1.183zm-3.6674-9.4643v1.183h1.2225l2.2004 4.4896-0.82517 1.4492c-0.097799 0.16562-0.15281 0.36083-0.15281 0.56786 0 0.65067 0.55011 1.183 1.2225 1.183h7.3348v-1.183h-7.0781c-0.085569 0-0.15281-0.06506-0.15281-0.14788l0.018337-0.071 0.55011-0.96418h4.5537c0.45843 0 0.86184-0.24252 1.0697-0.60926l2.1882-3.839c0.0489-0.08281 0.07334-0.18337 0.07334-0.28393 0-0.32534-0.27506-0.59152-0.61124-0.59152h-9.0402l-0.57456-1.183zm9.7798 9.4643c-0.67236 0-1.2164 0.53237-1.2164 1.183 0 0.65067 0.544 1.183 1.2164 1.183 0.67236 0 1.2225-0.53237 1.2225-1.183 0-0.65067-0.55011-1.183-1.2225-1.183z"
              fill="white"
              strokeWidth=".6013"
            />
          </g>
        </svg>
      </Lien>
    </button>
  )
}

BoutonChariot.propTypes = {
  codePageCible: PropTypes.string,
  classeCSS: PropTypes.string,
}
