import React from "react"
import PropTypes from "prop-types"

import BoutonChariot from "../boutons/BoutonChariot"
import Lien from "../navigation/Lien"
import { locales } from "../../../locales"
import { definirInnerHTML } from "../../js/client-es/utils"

export default function BoutonAcheterTirageAvecPrix(props) {
  return (
    <div id="boutique-bouton-achat-avec-prix-conteneur">
      <div id="boutique-bouton-achat-avec-prix-bordure-haut">
        <BoutonChariot
          classeCSS="boutique-bouton-achat-chariot"
          codePageCible={props.image[props.langue].tirage.code_page}
        />
        <Lien
          codePageCible={props.image[props.langue].tirage.code_page}
          classeCSS="lien-sans-effet"
        >
          <div
            id="boutique-bouton-achat-avec-prix-texte-conteneur"
            dangerouslySetInnerHTML={definirInnerHTML(
              obtenirTextePrixTirage(props.image, props.langue)
            )}
          />
        </Lien>
      </div>
    </div>
  )
}

function obtenirTextePrixTirage(image, langue) {
  return locales[langue].site.acheterUnTirageLightbox
    .replace("{0}", image[langue].tirage.prix_minimal)
    .replace("{1}", image[langue].tirage.prix_maximal)
}

BoutonAcheterTirageAvecPrix.propTypes = {
  image: PropTypes.object,
  langue: PropTypes.string,
}
