import React from "react"
import PropTypes from "prop-types"

import Lien from "../navigation/Lien"
import { locales } from "../../../locales"

export default function BoutonPartageFacebook({ classeCSS, page }) {
  return (
    <div className={classeCSS} page={page}>
      <Lien
        urlExterne={`https://www.facebook.com/sharer/sharer.php?u=${page.urlPartage}`}
        label={locales[page.langue].site.partagerSurFacebook}
      >
        <svg viewBox="0 0 32 32">
          <g id="Flat_copy">
            <g>
              <g>
                <path
                  d="M16,31.625c-8.616,0-15.625-7.01-15.625-15.625C0.375,7.384,7.384,0.375,16,0.375     c8.615,0,15.625,7.009,15.625,15.625C31.625,24.615,24.615,31.625,16,31.625z"
                  fill="#FFFFFF"
                />
                <path
                  d="M16,0.75c8.409,0,15.25,6.841,15.25,15.25S24.409,31.25,16,31.25S0.75,24.409,0.75,16S7.591,0.75,16,0.75      M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0L16,0z"
                  fill="#E5E5E5"
                />
              </g>
            </g>
            <path
              d="M13.69,24.903h3.679v-8.904h2.454l0.325-3.068h-2.779l0.004-1.536c0-0.8,0.076-1.229,1.224-1.229h1.534   V7.097h-2.455c-2.949,0-3.986,1.489-3.986,3.992v1.842h-1.838v3.069h1.838V24.903z"
              fill="#5F5F5F"
            />
          </g>
        </svg>
      </Lien>
    </div>
  )
}

BoutonPartageFacebook.propTypes = {
  classeCSS: PropTypes.string,
  page: PropTypes.object,
}
